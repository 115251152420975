$dark: #1a1a1a;
$red: #f05123;
$yellow: #FFC849;
$darkYellow: #C8992E;
$lightYellow: #FFF3D9;
$blue: #1b75bc;
$light: #FBFAF9;
$gray: #706969;
$midgray: #989090;
$lightgray: #E5E5E5;
$gradient: -webkit-linear-gradient(180deg,  $red, $yellow);

* {
  color: $dark;
  box-sizing: border-box;
}
p, li {
  color: $gray;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 300;
  list-style: none;
}
.bg-yellow {
  background: $yellow;
}
.white-box {
  padding: 25px 20px;
  border-radius: 7px;
  background: white;
  box-shadow: 0px 3px 45px 0px #00000020, 0px 2px 5px -4px #00000030;
}
.button-main {
  // margin-top: 20px;
  border: 0;
  border-radius: 3px;
  padding: 7px 25px;
  min-width: 175px;
  color: white;
  font-weight: 500;
  font-size: 1rem;
  background: white;
  position: relative;
  z-index: 2;
  display: inline-block;
  background: -webkit-linear-gradient(180deg,  $red, $yellow);
  cursor: pointer;
  transition: all .3s;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0px 3px 10px -2px #00000040;
  }
}
.h-screen {
  min-height: 100vh;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.illustrations {
  display: flex;
  flex-direction: column;
  align-items:center;
}
.input-block {
  padding: 10px 0;
  label {
    display: block;
    margin-bottom: 5px;
    color: $gray;
    font-size: 14px;
    font-weight: 300;
  }
  input, select {
    width: 100%;
    border: 1px solid $lightgray;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 1rem;
    font-weight: 500;

  }
}
.left-right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1024px;
  padding: 30px 0;
  transform-style: preserve-3d;
  .left, .right {
    width: 45%;
    transform-style: preserve-3d;
  }
  .image {
    z-index: 50;
    transform-style: preserve-3d;
    img {
      width: 100%;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
    &.content-left {
      flex-direction: column-reverse;
    }
    .left, .right {
      width: auto;
      max-width: 400px;
      margin: 0 auto;
    }
    .image {
      img {
        width: auto;
        max-height: 50vh;
      }
    }
  }

  .right.image {

  }
  .content {
    padding: 20px;
    padding-bottom: 100px;
    margin: 0 auto;
    h3 {
      font-weight: 600;
      color: $dark;
      font-size: 1.3rem;
      span {
        background: linear-gradient(0deg,  $red, $yellow);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
.App {
  background: $light;
  min-height: 100vh;
  .app-inner {
    min-height: 100vh;
  }
  .app-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    padding: 15px 25px;
    background-color: white;
    box-shadow: 0px 3px 25px 0px #00000020, 0px 2px 5px -4px #00000030;
    .logo img{
      height: 30px;
      display: block;
    }
    .header-inner {
      margin: 0 auto;
      display: flex;
      width: 100%;
      max-width: 900px;
    }
  }

  .login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
  }
  .login-wrapper {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: space-between;
    max-width: 1024px;
    .banner {
      margin-left: 50px;
      width: 40%;
      max-width: 600px;
      h1 {
        font-size: 56px;
        margin: 10px 0px;
        font-weight: 900;
        span {
          display: block;
          white-space: nowrap;
        }
        .future {
          background: -webkit-linear-gradient(180deg,  $red, $yellow);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      h4 {
        font-weight: 400;
        color: $gray;
      }
      @media(max-width: 800px) {
        h1 {
          font-size: 30px;
        }
      }
    }
    @media(max-width: 900px) {
      flex-direction: column;
      .banner {
        width: 100%;
        margin-left: 0;
        max-width: 400px;
        padding: 30px;
        padding-top: 100px;
      }
    }
  }
  .login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 25px 20px;
    border-radius: 7px;
    background: white;
    box-shadow: 0px 3px 45px 0px #00000020, 0px 2px 5px -4px #00000030;
    h2 {
      text-align: center;
    }
    form {
      padding: 5px;
      width: 100%;
      max-width: 500px;
      min-width: 300px;

    }
    .switch-type {
      background: transparent;
      border: 0;
      color: $gray;
      cursor: pointer;
      .highlight {
        color: $red;
        text-decoration: underline;
      }
    }
    .form-footer {
      text-align: center;
    }
    .login-button, .register-button {
      margin-top: 20px;
      border: 0;
      border-radius: 3px;
      padding: 7px 25px;
      min-width: 175px;
      color: white;
      font-weight: 500;
      font-size: 1rem;
      background: white;
      position: relative;
      z-index: 2;
      display: inline-block;
      background: -webkit-linear-gradient(180deg,  $red, $yellow);
      cursor: pointer;
      transition: all .3s;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0px 3px 10px -2px #00000040;
      }

    }
  }
  .consent-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .consent-box {
    margin-top: 100px;
    display: block;
    width: 100%;
    max-width: 600px;
    h3 {
      font-weight: 300;
    }
  }
  .consent-form {
    display: flex;
    align-items: flex-end;
    .type-name {
      width: 100%;
      margin-right: 15px;
    }
  }
  .covid-banner {
    margin-top: 50px;
    min-height: 50vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    .image {
      width: auto;
      min-width: 300px;
      img {
        max-height: 300px;
      }
    }
    .content {
      padding-bottom: 20px;
      margin: 0 40px;
    }
    h2 {
      font-size: 42px;
      font-weight: 900;
    }
    p {
      color: $dark;
    }
    .learn-more {
      background: white;
      border: 0;
      padding: 10px 25px;
      font-size: 1rem;
      border-radius: 3px;
      margin-top: 20px;
      transition: all .3s;
      cursor: pointer;
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0px 3px 10px -2px #00000040;
      }
    }
  }
  .footer {
    background: $dark;
    padding: 20px;
    * {
      color: $midgray;
    }
    .copywrite {
      text-align: center;
      margin: 5px 0;
      font-weight: 500;
    }
  }
}

.intake-form-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  // justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  h1 {
    font-weight: 700;
  }

  .intake-form {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    .submit-form {
      margin: 0 0 0 auto;
    }
  }
  .intake-form-header {
    max-width: 800px;
    margin-bottom: 10px;
    padding: 20px;
  }
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    h3 {
      margin-top: 0;
      margin-left: 20px;
    }
  }
  .form-group-fields {
    margin: 0 auto;
    width: 100%;
  }
  .form-group-row {
    display: flex;
  }
  .input-block {
    margin: 0 20px;
    flex-basis: 100%;
    flex-shrink: 1;
    flex-grow: 1;
    .react-datepicker-wrapper {
      width: 100%;
    }
    &.checkbox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-basis: calc(100% / 5);
      width: calc(100% / 5);
      input {
        width: auto;
      }
      label {
        margin-bottom: 3px;
      }
    }
  }
}

.dash-wrapper {
  padding: 0;
  padding-right: 20px;
  padding-top: 60px;
}
.user-dashboard {
  display: flex;
  .sidebar {
    padding: 0px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    border-right: 1px solid $lightgray;

    .sb-group {
      padding: 10px 0px;
      border-top: 1px solid $lightgray;
      border-bottom: 1px solid $lightgray;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 15px;
    }
    .user-image {
      margin: 20px auto;
    }
    h3 {
      text-align: center;
      margin: 0;
    }
    img {
      width: 150px;
      border-radius: 500px;
      height: 150px;
    }
    ul {
      padding: 0;
      li {
        margin-bottom: 10px;
      }
    }

  }
  .profile-main {
    width: 100%;
    padding: 20px 40px;
  }
  .user-details {
    width: 100%;
    max-width: 600px;
  }

  .info-block {
    margin-bottom: 15px;
    padding: 5px 20px;
    p {
      margin: 2px 0;
    }
    .label {
      font-weight: 600;
      font-size: 14px;
    }
  }
  .profile-box {
    margin-bottom: 25px;
    h3 {
      margin-top: 0;
    }
    ul {

      padding-left: 0;
      li {
        list-style: none;
        color: $gray;
        margin-bottom: 10px;
        font-weight: 300;
        font-size: 14px;
      }
    }
    .profile-box-group {
      padding: 20px 20px;
      margin: 0 -20px;
      margin-top: 20px;
      border-top: 1px solid $lightgray;
      display: flex;

      flex-wrap: wrap;
    }
    .filter-button {
      margin: 0;
      flex-basis: calc(100% / 6);
      flex-grow: 0;
      border: 0;
      padding: 0;
      background: transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      span {
        display: block;
      }
      .filter-circle {
        background: $lightYellow;
        height: 50px;
        width: 50px;
        border-radius: 1000px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 1.7rem;
        }
      }
      .filter-label {
        margin-top: 3px;
        color: $midgray;
        font-weight: 300;
      }
    }
  }
  .resource-listing {
    padding: 25px 20px;
    display: flex;
    .left-side {
      margin-right: auto;
      padding-right: 30px;
      p {
        font-size: 0.875rem;
        margin-bottom: 25px;
        // display: -webkit-box;
        // -webkit-line-clamp: 4;
        // -webkit-box-orient: vertical;
        // overflow: hidden;

      }
      .categories {
        display: flex;
        flex-wrap: wrap;
      }
      .cat-tag {
      background: $lightYellow;
      color: $darkYellow;
      // box-shadow: 0px 2px 15px 0px #00000020;
      margin-left: 0px;
      margin-right: 10px;
      margin-top: 10px;
      padding: 5px 10px;
      border-radius: 100px;
      font-size: 0.75rem;
      font-weight: 500;
      text-transform: uppercase;
      }
    }
    .right-side {
      width: 100%;
      max-width: 300px;
      flex-shrink: 0;
      border-left: 1px solid $lightgray;
      margin: -20px 10px;
      padding: 20px;
      ul {
        li {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          span {
            color: $midgray;
            font-size: 0.875rem;
          }
          svg {
            width: 25px;
            flex-shrink: 0;
            margin-right: 15px;
          }
          .url {
            word-break: break-all;
          }
        }
      }
    }
  }
  .split-boxes {
    display: flex;
    justify-content: space-between;
    padding: 0;

    .profile-box {
      width: calc(50% - 15px);

    }
    @media (max-width: 900px) {
      flex-wrap: wrap;
      .profile-box {
        width: 100%;
        margin-bottom: 25px;
      }
    }
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
    .sidebar {
      width: 100%;
      img {

        object-fit: cover;
      }
    }
    .profile-main {
      padding: 0;
    }

  }
  .dash-heading {
    text-transform: uppercase;
    color: $gray;
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .filter-tag {
    background: $lightYellow;
    color: $darkYellow;
    box-shadow: 0px 2px 15px 0px #00000020;
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 100px;
    font-size: 0.75rem;
    font-weight: 500;
  }
}

.resource-categories span {
  color: blue;
}

.resource-listing {
  border: 1px solid $lightgray;
  margin-bottom: 15px;
  padding: 10px 25px;
  border-radius: 3px;
}
.info-group {
  .label {
    margin-top: 8px;
    margin-bottom: 5px;
  }
  p {
    margin: 3px 0;
  }
}

.four-oh-four-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
}
