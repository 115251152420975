@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700;900&display=swap');
* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}
html {
  max-width: 100vw;
  overflow-x: hidden;
}
body {
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
}